$(function() {

  var $window = $(window);
  var performAnimations = true;

  function onResize() {
    if($window.width() >= 768 ) {
      performAnimations = true;
    } else {
      performAnimations = false;
      $(".reveal-on-scroll").addClass('js-animated');
    }
  }


  function revealOnScroll() {
    var scrolled = $window.scrollTop();
    if(scrolled > 100 && $window.width() >= 768) {
      $('.page-wrap').addClass('scrolled');
    } else {
      $('.page-wrap').removeClass('scrolled');
    }

    if(performAnimations) {
      var win_height_padded = $window.height() - 80;

      // Reveal content with an animation
      $(".reveal-on-scroll:not(.js-animated)").each(function () {
        var $this = $(this);
        var offsetTop = $this.offset().top;

        if (scrolled + win_height_padded > offsetTop) {
          if ($this.data('timeout')) {
            window.setTimeout(function () {
              $this.addClass('js-animated ' + $this.data('animation'));
            }, parseInt($this.data('timeout'), 10));
          } else {
            $this.addClass('js-animated ' + $this.data('animation'));
          }
        }
      });
      // Hide content
      /*$(".reveal-on-scroll.js-animated").each(function (index) {
          var $this = $(this);
          var offsetTop = $this.offset().top;
          if (scrolled + win_height_padded < offsetTop) {
              $(this).removeClass('js-animated fade-in')
          }
      });*/
    }
  }

  $window.resize(onResize);
  onResize(); // on page load
  $window.on('scroll', revealOnScroll);
  revealOnScroll(); // on page load
});